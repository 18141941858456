import tableauBordApi from "@/api/tableauBordApi"

export async function confirmDeleteTableauBord(tableauBordId, tableauBordNom, deleteCallback = null) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer TableauBord',
        text: `Etes-vous sûr de vouloir supprimer le Tableau de Bord ${tableauBordNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deleteTableauBord(tableauBordId, deleteCallback);
}

export async function deleteTableauBord(tableauBordId, deleteCallback = null) {
    if(this.hasOwnProperty("deleting"))
        this.deleting = true;
    try {
        await tableauBordApi.deleteTableauBord(tableauBordId);
        this.showToast("success", "Tableau Bord supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(tableauBordId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting"))
            this.deleting = false;
    }
}